<template>
  <v-form ref="loginForm">
    <v-flex row wrap>
      <v-spacer class="disappearing-spacer" />
      <v-flex xs12 md5 lg5 layout column class="login-container">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-flex layout column class="login-form">
            <div>
            <img class="bob-logo" src="@/assets/bobgreenlogo.png" />
            </div>
          <div class="login-header">Application Process Complete!</div>
        </v-flex>
        <v-spacer />
      </v-flex>
      <v-spacer />
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helper'
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...authComputed,
  },
  metaInfo: {
    title: `Bob - Application`,
  },
  async mounted() {
  },
  methods: {
    ...mapActions({
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.disappearing-spacer {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.disappearing-hero {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.login-hero {
  @media only screen and (max-width: 800px) {
    height: 100vh;
    width: 70vh;
  }
  width: 50vw;
}
.bob-logo {
  @media only screen and (max-width: 960px) {
    width: 20vh;
  }
  width: 10vw;
}
.login-container {
  height: 70vh;
}

.login-form {
  align-items: center;
}

.login-input-container {
  width: 56%;
}

.login-remember-me {
  width: 56%;
  margin-top: -30px;
  margin-bottom: 30px;
}

.login-input {
  width: 100%;
}

.login-button {
  width: 100%;
}

.login-header {
  letter-spacing: 0.01em;
  font-size: 2.5em;
  font-weight: 500;
  margin-bottom: 50px;
    @media screen and (min-width: 481px) and (max-width: 768px) {
            font-size: 1em;
    }

    @media screen and (min-width: 320px) and (max-width: 480px) {
            font-size: 1em;
    }
}


 </style>
