import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/state/utils/auth'
import device from "vue-device-detector"
Vue.use(Router)
Vue.use(device)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: () => import('@/views/RouterOutlet.vue'),
      meta: { public: true },
      children: [
        {
          path: '/login',
          component: () => import('@/views/Login.vue'),
          name: 'login',
          meta: { public: true },
        },
        {
          path: '/logout',
          component: () => import('@/views/Login.vue'),
          name: 'logout',
          meta: { public: true },
        },
        {
          path: '/register-invitation/:invitationId',
          component: () => import('@/views/RegisterInvitation.vue'),
          name: 'registerInvitationId',
          meta: { public: true },
        },
        {
          path: '/forgot-password',
          component: () => import('@/views/ForgotPassword.vue'),
          name: 'forgotPassword',
          meta: { public: true },
        },
        {
          path: '/reset-password/:passwordResetId',
          component: () => import('@/views/ResetPassword.vue'),
          name: 'passwordResetWithId',
          meta: { public: true },
        },
        {
          path: '/plaid',
          component: () => import('@/views/ResetPassword.vue'),
          name: 'passwordResetWithId',
          meta: { public: true },
        },
        {
          path: '/verifyEmail/:verifyEmailId',
          component: () => import('@/views/VerifyEmail.vue'),
          name: 'verifyEmailWithId',
          meta: { public: true },
        },
        {
          path: '/applicationVerified',
          component: () => import('@/views/ApplicationVerified.vue'),
          name: 'applicationVerified',
          meta: { public: true },
        },
        {
          path: '/advisor',
          component: () => import('@/views/AdvisorMain.vue'),
          name: 'advisor',
          children: [
            {
              path: 'models/:id',
              component: () => import('@/flows/advisor/ViewModel.vue'),
              name: 'viewModel',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
            {
              path: 'clients/:id',
              component: () => import('@/flows/advisor/ViewClient.vue'),
              name: 'viewClient',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
            {
              path: 'trade-review',
              component: () => import('@/flows/advisor/ViewTradeReview.vue'),
              name: 'tradeReview',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
            {
              path: 'performance',
              component: () =>
                import('@/flows/advisor/ViewPerformanceMain.vue'),
              name: 'performance',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
            {
              path: 'models',
              component: () => import('@/flows/advisor/ViewModels.vue'),
              name: 'models',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
            {
              path: 'client-management',
              component: () =>
                import('@/flows/advisor/ViewClientManagement.vue'),
              name: 'clientManagement',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
            {
              path: 'home',
              component: () => import('@/flows/advisor/ViewAdvisorHome.vue'),
              name: 'advisorHome',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager','retail'],
              },
            },
          ],
        },
        {
          path: '/retail',
          component: () => import('@/views/RetailMain.vue'),
          name: 'retail',
          children: [
            {
              path: 'performance',
              component: () =>
                import('@/flows/advisor/ViewPerformanceMain.vue'),
              name: 'clientPerformance',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['retailClient'],
              },
            },
            {
              path: 'account',
              component: () => import('@/flows/retail/ViewRetailAccount.vue'),
              name: 'clientAccount',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['retailClient'],
              },
            },
            {
              path: 'home',
              component: () => import('@/flows/retail/ViewRetailHome.vue'),
              name: 'retailHome',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['retailClient'],
              },
            },
          ],
        },
        {
          path: '/broker/callback',
          component: () => import('@/views/BrokerCallback.vue'),
          name: 'brokerCallback',
          meta: { public: false },
        },
        {
          path: '/access-error',
          component: () => import('@/views/AccessError.vue'),
          name: 'accessError',
          meta: { public: true },
        },
        {
          path: '/not-found',
          component: () => import('@/views/FourOhFour.vue'),
          name: 'notFound',
          meta: { public: true },
        },
        {
          path: '/oops',
          component: () => import('@/views/Oops.vue'),
          name: 'oops',
          meta: { public: true },
        },
        {
          path: '/login-router',
          component: () => import('@/views/LoginRouter.vue'),
          name: 'loginRouter',
          meta: { public: true },
        },
      ],
    },
  ],
})

router.beforeEach((routeTo, routeFrom, next) => {
  try {
    if (routeTo.path === '/') {
      if (auth.isAuthenticated()) {
        return router.routeToHome(next)
      }
      return router.push('/login')
    }

    if (routeTo.name === 'logout') {
      auth.logout()
      return next()
    }

    // LOGOUT
    if (routeTo.name === 'logout') {
      auth.logout()
      return next()
    }

    if (router.isPublic(routeTo)) {
      // REDIRECT TO LOGIN PORTAL
      return next()
    }

    // NO MATCHING ROUTE
    // REDIRECT TO PROPER LANDING PAGE BASED ON USER
    if ((routeTo.matched || []).length === 0) {
      return router.routeToHome(next)
    }

    if (routeTo.name === 'loginRouter') {
      return router.routeToHome(next)
    }

    // CHECK EXPIRED SESSION
    if (!auth.isAuthenticated()) {
      return router.push('login')
    }

    // CHECK ROUTE PERMISSIONS BEFORE ROUTING
    if (!router.hasPermission(routeTo)) {
      return router.routeToHome(next)
    }

    // GO!!!
    next()
  } catch (e) {
    console.error('ROUTE ERROR', e)
  }
})

router.routeToHome = (next) => {
  const routeMap = {
    institutional: 'advisorHome',
    retail: 'retailHome',
  }
  if (!auth.isAuthenticated()) {
    return next({ name: 'login' })
  }
  const user = auth.getProfile()
  const route = routeMap[user?.userType]
  if (route) {
    next({ name: route })
  } else {
    next({ name: 'oops' })
  }
}

router.hasPermission = (route) => {
  const {
    public: publicRoute = false,
    disallowedRoles = [],
    allowedRoles = [],
  } = route.meta || {}
  if (publicRoute) {
    return true
  }
  const user = auth.getProfile()
  const userProfile = user?.userProfile || []
  const roleCodes = userProfile.map((u) => u.roleCode)
  const disallowed = disallowedRoles.find((d) => roleCodes.includes(d))
  const allowed = allowedRoles.find((d) => roleCodes.includes(d))
  if (disallowedRoles.length && disallowed) {
    return false
  }
  if (allowedRoles.length) {
    if (allowed) return true
  } else {
    return true
  }
  return false
}

router.isPublic = (route) => {
  const { public: publicRoute = false } = route.meta || {}
  if (publicRoute) {
    return true
  }
  return false
}

export default router
