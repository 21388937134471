<template>
  <v-form ref="loginForm">
    <v-flex row wrap>
      <v-spacer class="disappearing-spacer" />
      <!-- <v-flex xs6 class="disappearing-hero">
        <v-flex align-self-end>
          <img class="login-hero" src="@/assets/f.png" />
        </v-flex>
      </v-flex> -->
      <v-flex xs12 md5 lg5 layout column class="login-container">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-flex layout column class="login-form">
        <div class="login-header" >
          <img class="login-hero" src="@/assets/f.png" />
        </div>
          <div class="login-header">You've been invited!</div>
            <div
              class="login-header"
              @click="route"
            >
              <img class="login-hero" src="@/assets/app-store-badge.png" />
            </div>
          <div               
            class="login-header"
            @click="route">
            <img class="login-hero" src="@/assets/google-play-badge.png" />
         </div>
        </v-flex>
        <v-spacer />
      </v-flex>
      <v-spacer />
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helper'
export default {
  data() {
    return {
      loading: false,
      password: undefined,
      confirmPassword: undefined,
      email: undefined,
      invitationId: false,
      authError: false,
      invitationSuccess: false,
      validInvitation: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  metaInfo: {
    title: `Bob - Invitation`,
  },
  async mounted() {
    this.invitationId = this.$route.params?.invitationId
  },
  methods: {
    ...mapActions({
      setRecordInvitedUser: 'auth/setRecordInvitedUser',
    }),
    async route() {
        this.routeTo = this.$device.android || this.$device.androidPhone ? 'https://play.google.com/store/apps/details?id=com.letbob.bobllc' : 'https://apps.apple.com/us/app/let-bob/id1552851134'
        this.userType = this.$device.android || this.$device.androidPhone ? 'android' : 'ios'
        fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(async ({ ip }) => {
          await this.setRecordInvitedUser({ipAddress: ip, userType: this.userType, userId: this.invitationId})
          window.location.href = this.routeTo;
        });
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

// .disappearing-spacer {
//   @media only screen and (max-width: 960px) {
//     display: none;
//   }
// }

// .disappearing-hero {
//   @media only screen and (max-width: 960px) {
//     display: none;
//   }
// }
// .login-hero {
//   @media only screen and (max-width: 800px) {
//     height: 100vh;
//     width: 70vh;
//   }
//   width: 50vw;
// }

.login-container {
  height: 70vh;
}

.login-form {
  align-items: center;
}

.login-input-container {
  width: 56%;
}

.login-remember-me {
  width: 56%;
  margin-top: -30px;
  margin-bottom: 30px;
}

.login-input {
  width: 100%;
}

.login-button {
  width: 100%;
}

.login-header {
  letter-spacing: 0.01em;
  font-size: 2.5em;
  font-weight: 500;
  margin-bottom: 50px;
}
</style>
