import auth from '@/state/utils/auth'
import axios from 'axios'
const baseUrl = `https://prod.letbob.com`

export const namespaced = true

export const state = {
  currentUser: undefined,
  rememberMe: false,
}

export const getters = {
  getCurrentUser() {
    return state.currentUser
  },
  getCurrentUserName() {
    const { currentUser } = state
    const { firstName, lastName } = currentUser || {}
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName
  },
  getCurrentUserInitials() {
    return state.currentUser
  },
  getRememberMe() {
    return state.rememberMe
  },
}

export const mutations = {
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser
  },
  setRememberMe(state, value) {
    state.rememberMe = value
    auth.setRememberMe(value)
  },
  saveToken() {},
}

export const actions = {
  async init({ commit }) {
    const token = auth.getToken()
    auth.setToken(token)
    const currentUser = auth.getProfile()
    commit('user/setCurrentUser', currentUser, { root: true })
    commit('setRememberMe', auth.getRememberMe())
  },
  async login(store, payload) {
    const loginResponse = await axios
      .post(`${baseUrl}/v0/login`, payload)
      .catch((e) => console.log(e.response))
    if (loginResponse?.status !== 200) {
      return false
    }
    auth.setToken(loginResponse?.data)
    return true
  },
  async createUser(_, payload) {
    const createResponse = await axios
      .post(`${baseUrl}/v0/createUser`, payload)
      .catch((e) => console.log(e.response))
    if (createResponse?.status !== 200) {
      return false
    }
    return true
  },
  async sendResetPassword(_, payload) {
    const sendResetPasswordResponse = await axios
      .post(`${baseUrl}/v0/sendResetPassword`, payload)
      .catch((e) => console.log(e.response))
    if (sendResetPasswordResponse?.status !== 200) {
      return false
    }
    return true
  },
  async resetPassword(_, payload) {
    const resetPasswordResponse = await axios
      .post(`${baseUrl}/v0/resetPassword`, payload)
      .catch((e) => console.log(e.response))
    if (resetPasswordResponse?.status !== 200) {
      return false
    }
    return true
  },
  async verifyEmail(_, payload) {
    const resetPasswordResponse = await axios
      .post(`${baseUrl}/v0/verifyEmail`, payload)
      .catch((e) => console.log(e.response))
    if (resetPasswordResponse?.status !== 200) {
      return false
    }
    return true
  },
  async getUser({ commit }) {
    const userResponse = await auth.getUser()
    commit('setCurrentUser', userResponse)
    return userResponse
  },
  async isAuthenticated() {},

  async setRecordInvitedUser(_, payload) {
     await axios.post('/v0/user.inviteFriend',payload)
    return true
  }
}
